import { AllRoutes } from "./components/routes/AllRoutes";
import { ToastContainer } from 'react-toastify'

function App() {
  return (
    < >
      <ToastContainer />
      <AllRoutes />
    </>
  );
}

export default App;
