import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { FeedbackPage } from '../pages/Feedback-page';
import { RedemptionForm } from '../pages/Redemption-form';
import { Redemption } from '../pages/Redemption';
import { CheckoutPage } from '../pages/Checkout-page';
import { ProductListPage } from '../pages/Product-list-page';
import { RedeemGiftPage } from '../pages/Redeem-gift-page';
import { PersonalizedVideo } from '../pages/Personalized-video';
import { OtherCountryRedeemGiftPage } from '../pages/Other-country-redeem-page';
import { MultiplePackListPage } from '../pages/Multi-pack-list';

export const AllRoutes = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Redemption />}></Route>
          <Route path="/redemption/checkout" element={<CheckoutPage />}></Route>
          <Route path="/redemption/multi/packs" element={<MultiplePackListPage />}></Route>
          <Route path="/redemption/details" element={<RedemptionForm />} ></Route>
          <Route path="/redemption/feedback" element={<FeedbackPage />}></Route>
          <Route path="/redemption/products" element={<ProductListPage />}></Route>
          <Route path="/redemption/redeem-gift" element={<RedeemGiftPage />}></Route>
          <Route path='/redemption/personalized-video' element={<PersonalizedVideo />}></Route>
          <Route path='/redemption/redeem-gift/other-country' element={<OtherCountryRedeemGiftPage />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}
